export const cities = [
  "Abbottabad",
  "Adilpur",
  "Ahmadpur East",
  "Ahmadpur Sial",
  "Akora",
  "Aliabad",
  "Alik Ghund",
  "Alipur",
  "Alizai",
  "Alpurai",
  "Aman Garh",
  "Amirabad",
  "Arifwala",
  "Ashanagro Koto",
  "Athmuqam",
  "Attock City",
  "Awaran",
  "Baddomalhi",
  "Badin",
  "Baffa",
  "Bagarji",
  "Bagh",
  "Bahawalnagar",
  "Bahawalpur",
  "Bakhri Ahmad Khan",
  "Bandhi",
  "Bannu",
  "Barishal",
  "Barkhan",
  "Basirpur",
  "Basti Dosa",
  "Bat Khela",
  "Battagram",
  "Begowala",
  "Bela",
  "Berani",
  "Bhag",
  "Bhakkar",
  "Bhalwal",
  "Bhan",
  "Bhawana",
  "Bhera",
  "Bhimbar",
  "Bhiria",
  "Bhit Shah",
  "Bhopalwala",
  "Bozdar Wada",
  "Bulri",
  "Būrewāla",
  "Chak",
  "Chak Azam Sahu",
  "Chak Five Hundred Seventy-five",
  "Chak Jhumra",
  "Chak One Hundred Twenty Nine Left",
  "Chak Thirty-one -Eleven Left",
  "Chak Two Hundred Forty-nine Thal Development Authority",
  "Chakwal",
  "Chaman",
  "Chamber",
  "Charsadda",
  "Chawinda",
  "Chenab Nagar",
  "Cherat Cantonement",
  "Chhor",
  "Chichawatni",
  "Chilas",
  "Chiniot",
  "Chishtian",
  "Chitral",
  "Choa Saidan Shah",
  "Chowki Jamali",
  "Chunian",
  "Dadhar",
  "Dadu",
  "Daggar",
  "Daira Din Panah",
  "Dajal",
  "Dalbandin",
  "Dandot RS",
  "Daromehar",
  "Darya Khan",
  "Darya Khan Marri",
  "Daska Kalan",
  "Dasu",
  "Daud Khel",
  "Dau",
  "Daultala",
  "Daur",
  "Dera Allahyar",
  "Dera Bugti",
  "Dera Ghazi Khan",
  "Dera Ismail Khan",
  "Dera Murad Jamali",
  "Dhanot",
  "Dhaunkal",
  "Dhoro Naro",
  "Eidgah",
  "Eminabad",
  "Faisalabad",
  "Faqirwali",
  "Faruka",
  "Fazilpur",
  "Fort Abbas",
  "Gadani",
  "Gilgit",
  "Gojra",
  "Gujar Khan",
  "Gujranwala",
  "Gujrat",
  "Gulishah Kach",
  "Gwadar",
  "Hadali",
  "Hafizabad",
  "Hala",
  "Hangu",
  "Haripur",
  "Havelian",
  "Hazro City",
  "Hingorja",
  "Hujra Shah Muqim",
  "Hyderabad",
  "Islamabad",
  "Islamkot",
  "Jacobabad",
  "Jahanian Shah",
  "Jalalpur Jattan",
  "Jalalpur Pirwala",
  "Jampur",
  "Jamshoro",
  "Jand",
  "Jandiala Sher Khan",
  "Jaranwala",
  "Jati",
  "Jatoi Shimali",
  "Jauharabad",
  "Jhang City",
  "Jhang Sadr",
  "Jhawarian",
  "Jhelum",
  "Jhol",
  "Jiwani",
  "Johi",
  "Kalur Kot",
  "Kamalia",
  "Kamar Mushani",
  "Kanganpur",
  "Karachi",
  "Karak",
  "Karaundi",
  "Kario Ghanwar",
  "Karor",
  "Kashmor",
  "Kasur",
  "Khairpur",
  "Khairpur Mir’s",
  "Khairpur Nathan Shah",
  "Khairpur Tamewah",
  "Kharian",
  "Khewra",
  "Khurrianwala",
  "Khushāb",
  "Khuzdar",
  "Kohat",
  "Kohlu",
  "Kotli",
  "Lachi",
  "Ladhewala Waraich",
  "Lahore",
  "Lakhi",
  "Lala Musa",
  "Lalian",
  "Landi Kotal",
  "Larkana",
  "Layyah",
  "Liliani",
  "Lodhran",
  "Loralai",
  "Multan",
  "Mandi Bahauddin",
  "Matli",
  "Mian Channun",
  "Mianwali",
  "Mustafābād",
  "Muzaffargarh",
  "Muzaffarābād",
  "Nawabshah",
  "Nazir Town",
  "New Mirpur",
  "Noorabad",
  "Nowshera",
  "Nushki",
  "Okara",
  "Pattoki",
  "Peshawar",
  "Quetta",
  "Rahim Yar Khan",
  "Rawalakot",
  "Rawalpindi",
  "Renala Khurd",
  "Risalpur Cantonment",
  "Swat",
  "Saddiqabad",
  "Sahiwal",
  "Sanjwal",
  "Sann",
  "Sarai Alamgir",
  "Sarai Naurang",
  "Sarai Sidhu",
  "Sargodha",
  "Shahdadpur",
  "Shahkot",
  "Shahpur",
  "Shahr Sultan",
  "Shakargarh",
  "Sharqpur Sharif",
  "Sheikhupura",
  "Shekhupura",
  "Shikarpur",
  "Shingli Bala",
  "Sialkot",
  "Skardu",
  "Sobhodero",
  "Sodhri",
  "Sohbatpur",
  "Sukheke Mandi",
  "Sukkur",
  "Surab",
  "Surkhpur",
  "Swabi",
  "Thal",
  "Thul",
  "Timargara",
  "Toba Tek Singh",
  "Topi",
  "Turbat",
  "Ubauro",
  "Umarkot",
  "Upper Dir",
  "Vihari",
  "Wazirabad",
  "Yazman",
  "Zafarwal",
  "Zhob",
  "Panjgur",
  "Hunza",
  "Ghizer",
  "Mirpur Khas",
  "Umerkot",
  "Mardan",
  "Mingora",
  "Mansehra",
  "Sibi",
  "Mirpur",
  "Bhimber",
  "Muzaffarabad",
];
