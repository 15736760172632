<template>
  <div>
    <section class="course-cateory-section programs-offer">
      <div class="category-cntr">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="generic-wizard-heading">Select A Category</div>
              <p class="generic-wizard-subheading">Don’t worry you are just a few clicks away.</p>
            </div>
          </div>
          <categories />
        </div>
        <div class="circle-pattern">
          <img src="/assets/images/banner/circle-pattern.svg " alt="" />
        </div>
      </div>
      <div class="circle-pattern-2">
        <img src="/assets/images/banner/circle-pattern-2.svg " alt="" />
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { classes as classesStoreKeys, course as courseStoreKeys } from "../../../store/keys";
//import classesStoreModule from "../../../store/modules/course/class";
import { appConstants } from "../../../common/constants";
import categories from "../../../components/categories.vue";
export default {
  components: {
    categories,
  },
  // Client-side only
  metaInfo: {
    title: "Free Trial | KIPS VIRTUAL",
    titleTemplate: "",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  mounted() {},
  created() {},

  asyncData({ store, route }) {},
  computed: {
    ...mapGetters(classesStoreKeys.namespace, classesStoreKeys.getters),
    ...mapGetters(courseStoreKeys.namespace, courseStoreKeys.getters),
  },
  // Server-side only
  serverPrefetch() {},
  methods: {},
};
</script>
